<template>
    <div class="internal-sections my-click">
        <el-row :gutter="20">
            <el-col :span="18">
                <div class="d-flexk a-i-center">
                    <h3 style="margin-right: 20px">
                        Tibbiy Uskunalar Ro'yxati
                    </h3>
                    <div class="filter-internal-block">
                        <el-input
                            prefix-icon="el-icon-search"
                            v-model="filterForm.search"
                            size="small"
                        ></el-input>
                    </div>
                </div>
            </el-col>

            <el-col :span="6" class="btm-2">
                <crm-create-and-column-settings
                    @c-create="drawerCreate = true"
                    :columns="columns"
                    @c-change="updateColumn"
                >
                </crm-create-and-column-settings>
            </el-col>
        </el-row>
        <div class="internal-sections-table">
            <table class="table-my-code table-bordered" v-loading="loadingData">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.name.show">
                            {{ columns.name.title }}
                        </th>

                        <th v-if="columns.filial.show">
                            {{ columns.filial.title }}
                        </th>

                        <th v-if="columns.date_of_manufacture.show">
                            {{ columns.date_of_manufacture.title }}
                        </th>

                        <th v-if="columns.series_and_number.show">
                            {{ columns.series_and_number.title }}
                        </th>

                        <th v-if="columns.document_number.show">
                            {{ columns.document_number.title }}
                        </th>

                        <th v-if="columns.document_date.show">
                            {{ columns.document_date.title }}
                        </th>

                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.name.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.name"
                                :placeholder="columns.name.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.filial.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.filial"
                                :placeholder="columns.filial.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.date_of_manufacture.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.date_of_manufacture"
                                :placeholder="columns.date_of_manufacture.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.series_and_number.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.series_and_number"
                                :placeholder="columns.series_and_number.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.document_number.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.document_number"
                                :placeholder="columns.document_number.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.document_date.show">
                            <el-date-picker
                                :placeholder="columns.document_date.title"
                                v-model="filterForm.document_date"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.created_at.show">
                            <el-date-picker
                                :placeholder="columns.created_at.title"
                                v-model="filterForm.created_at"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.updated_at.show">
                            <el-date-picker
                                :placeholder="columns.updated_at.title"
                                v-model="filterForm.updated_at"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="(item, index) in equipments"
                        :key="index"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ item.id }}</td>

                        <td v-if="columns.name.show">
                            {{ item.name }}
                        </td>

                        <td v-if="columns.filial.show">
                            {{ item.filial ? item.filial.name : "" }}
                        </td>

                        <td v-if="columns.date_of_manufacture.show">
                            {{ item.date_of_manufacture }}
                        </td>

                        <td v-if="columns.series_and_number.show">
                            {{ item.series_and_number }}
                        </td>

                        <td v-if="columns.document_number.show">
                            {{ item.document_number }}
                        </td>

                        <td v-if="columns.document_date.show">
                            {{ item.document_date }}
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ item.created_at }}
                        </td>
                        <td v-if="columns.updated_at.show">
                            {{ item.updated_at }}
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <crm-settings
                                :name="$options.name"
                                :model="item"
                                :permissionShow="'medicalEquipmentsClinicController@update'"
                                :permissionDestroy="'medicalEquipmentsClinicController@destroy'"
                                :actions="actions"
                                @edit="edit"
                                @delete="destroy"
                            ></crm-settings>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my-pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                size="95%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                ></crm-create>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="95%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import list from "@/utils/mixins/models/listForModels";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
export default {
    name: "medicalEquipmentsClinic",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
    },
    computed: {
        ...mapGetters({
            equipments: "medicalEquipments/equipments",
            columns: "medicalEquipments/columns",
            pagination: "medicalEquipments/pagination",
            statues: "medicalEquipments/statues",
            filter: "medicalEquipments/filter",
            sort: "medicalEquipments/sort",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions({
            updateList: "medicalEquipments/filteredMedicalEquipmentsByClinicId",
            setPagination: "medicalEquipments/setPagination",
            updateSort: "medicalEquipments/updateSort",
            updateFilter: "medicalEquipments/updateFilter",
            updateColumn: "medicalEquipments/updateColumn",
            updatePagination: "medicalEquipments/updatePagination",
            show: "medicalEquipments/show",
            empty: "medicalEquipments/empty",
            delete: "medicalEquipments/destroy",
            refreshData: "medicalEquipments/refreshData",
        }),
        fetchData() { 
            if (this.$route.params.hasOwnProperty('id')) {
                let path = this.$route.path.substring(1,13);
                this.model_id = (path === 'officeClinic') ? parseFloat(this.$route.params.id) : null;
            }else{
                this.model_id = null;
            }
            const query = {
                ...this.filter,
                ...this.pagination,
                ...this.sort,
                'model_id': this.model_id
            };
            if (!this.loadingData) {
                this.loadingData = true;    
                this.updateList(query).then(res => {
                    this.loadingData = false;
                }).catch(err => {
                    this.loading = false;
                });
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("medicalEquipments/EMPTY_MEDICAL_EQUIPMENTS");
        next();
    },
};
</script>
